.transparent {
  height: 100%;
  width: 100%;
  background-color: white;
  border: 0;
  z-index: 100;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.loaderHeight {
  padding-bottom: 150px;
}
