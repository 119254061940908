.notificationBar {
  position: fixed;
  z-index: 100000;
  width: 100%;
  min-height: 54px;
  border-radius: 0;
  padding: 10px;
  transition: 500ms;
  margin-top: -250px;
  transition-property: all;
}

.notificationOpen {
  margin-top: 0;
}

.notificationClose {
  margin-top: -250px;
}

.alertSuccess {
  color: #FFF;
  border-color: #c3e6cb;
}

.alertDanger {
  color: #FFF;
  border-color: #ffcac7;
}
